export const headers = [
  "Contact Person Name",
  "Phone",
  "Email",
  "Department",
  "Designation",
  "Receive Billing Information",
  "Actions",
];

export const keysToBeUsedInTable = {
  "Contact Person Name": "name",
  Phone: "phone",
  Email: "email",
  Department: "department",
  Designation: "designation",
  "Receive Billing Information": "billingCommunication",
};
