import axios from "axios";
import toastr from "toastr";
import { getQueryParam } from "../../../../_helpers/Common/helper";
import { FileUploadService } from "../../../../_services/CommnService/FileUploadService/FileUploadService";
import { COTTON_BALES } from "../PostYourReq/constant";
import { Validation } from "./../../../../_helpers/validation/Validation";
import { isMobile } from "../helperFunc";
import {
  downloadDocThroughDoms,
  generateSignedUrl,
  saveDocThroughDoms,
} from "../../../../_services/Arya2.0/DomsApi";

const downloadAndroidApp = (appLink = "") => {
  window.location.href = appLink
    ? "https://play.google.com/store/apps/details?id=com.app.aryashakti"
    : "https://play.google.com/store/apps/details?id=arya.ag";
};

export const dateFormatChange = (newDate) => {
  const inputDate = newDate;
  const dateObject = new Date(inputDate);

  const options = { day: "2-digit", month: "short", year: "numeric" };
  let formattedDate = dateObject.toLocaleDateString("en-IN", options);

  return formattedDate.replaceAll("-", " ");
};
export const isNumberOnly = (input) => {
  var numberOnly = /^[0-9]+$/;
  return numberOnly.test(input);
};

export const getFirstDateOfPreviousMonth = () => {
  let date = new Date(); // Get the current date
  // date.setDate(1); // Set the date to the first day of the current month
  // date.setMonth(date.getMonth() - 1); // Set the month to the previous month

  // Format the date as YYYY-MM-DD
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so we add 1
  let day = String(date.getDate()).padStart(2, "0"); // getDate() returns the day of the month

  return `${year}-${month}-${day}`;
};

const downloadIosApp = (appLink = "") => {
  window.location.href = appLink
    ? "https://apps.apple.com/in/app/arya-shakti/id1635085520"
    : "https://apps.apple.com/app/arya-marketplace/id1621681339";
};

export const isObjectEmpty = (inputObject = {}) => {
  return Object.keys(inputObject).length === 0;
};

export const downloadClick = (appLink = "") => {
  let userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  let browserList = ["edg", "firefox", "chrome"];
  let isAndroid = false;
  browserList.forEach((device) => {
    if (navigator.userAgent.toLowerCase().includes(device)) {
      isAndroid = true;
    }
  });

  if (navigator.userAgent.toLowerCase().indexOf("android") > -1 && isAndroid) {
    downloadAndroidApp(appLink);
  } else if (ios && safari) {
    downloadIosApp(appLink);
  } else {
    window.location.href = "/";
  }
};

export const titleCase = (str = "") => {
  return str
    ?.toLowerCase()
    ?.split(" ")
    ?.map(function (word) {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    })
    ?.join(" ");
};

const handleNull = (quote) => {
  return quote ?? 0;
};
export const FilterLeadList = (
  inputArr,
  type = "buy",
  isPopular = false,
  translation
) => {
  return inputArr.map((el) => ({
    id: el.unique_id,
    createdById: el.user_id,
    // type: "buy",
    title: titleCase(
      [el.commodity_lang, el.variety]
        .filter(Boolean)
        .map((el) => el.toLowerCase())
        .join(`, `)
    ),
    quantityText: (el?.qty_mt).replace("MT", BaleOrMT(el.commodity)),
    priceText: `₹${parseFloat(el.per_mt_price).toLocaleString("en-IN", {
      maximumFractionDigits: 2,
    })}`,
    imgSrc: el?.image_link?.[0] ? el?.image_link?.[0] : el.commodity_image,
    addressText: titleCase(el.address_lang.toLowerCase()),
    quotesText: `${handleNull(el.total_quotes)} 
    ${
      handleNull(el.total_quotes) == 1
        ? translation("quote recieved")
        : translation("quotes recieved")
    } `,
    postedText: el?.buyer_listing_time_ago,
    isCertified: type == "buy" ? false : el?.arya_warehouse == 1 ? true : false,
    wareHouseName: el?.warehouse_name ? titleCase(el?.warehouse_name) : "",
    wareHouseAddress: el?.address_lang
      ? titleCase(el.address_lang.toLowerCase())
      : "",
    warehouseText: isPopular
      ? titleCase(
          [el?.warehouse_name, el?.address]
            .filter(Boolean)
            .map((el) => el.toLowerCase())
            .join(", ")
        )
      : undefined,
  }));
};

export const FilterPopularLeadList = (
  inputArr,
  type = "buy",
  isPopular = false,
  usertype = false
) => {
  return inputArr.map((el) => ({
    lead_id: el.id,
    id: el.unique_id,
    isBookMarked: el.is_in_wishlist,
    createdById: el.user_id,
    user_type: el.user_type,
    // type: "buy",
    title:
      titleCase(
        [el.commodity_lang, el.variety]
          .filter(Boolean)
          .map((el) => el.toLowerCase())
          .join(`, `)
      ) + (el.user_type && !isMobile && usertype ? "," : ""),
    quantityText: (el?.qty_mt).replace("MT", BaleOrMT(el.commodity)),
    priceText: `₹${parseFloat(el.per_mt_price).toLocaleString("en-IN", {
      maximumFractionDigits: 2,
    })}`,
    rate_per_scale: `₹${parseFloat(el.per_mt_price).toLocaleString(
      "en-IN"
    )}/${BaleOrMT(el.commodity)}`,
    imgSrc:
      el.image_link?.length > 0 ? el?.image_link?.[0] : el.commodity_image,
    addressText: titleCase(el.address_lang.toLowerCase()),
    postedText: el?.buyer_listing_time_ago,
    isCertified: type == "buy" ? false : el?.arya_warehouse == 1 ? true : false,
    warehouseText: isPopular
      ? titleCase(
          [el?.address_lang]
            .filter(Boolean)
            .map((el) => el.toLowerCase())
            .join(", ")
        )
      : undefined,
  }));
};

export const maxTwoDigitFractionHandler = (number = 0, decimals = 2) => {
  return parseFloat(number || 0).toLocaleString("en-IN", {
    maximumFractionDigits: decimals,
  });
};

export const filterSimilarLeadList = (
  inputArr,
  type = "buy",
  translation = () => null
) => {
  // {
  //   createdById: 1,
  //   id: 3,
  //   aryaCertified: true,
  //   address: "gautam buddha nagar, uttar pradesh",
  //   day: "1 day ago",
  //   image:
  //     "https://a2z-web.s3.ap-south-1.amazonaws.com/arya/warehouseRequests/img_1603342197.png",
  //   rows: ["wheat", "12MT", "Price ₹11000/MT"],
  // }
  return inputArr.map((el) => ({
    lead_id: el.id,
    id: el.unique_id,
    isBookMarked: el.is_in_wishlist,
    createdById: el.user_id,
    // type: "buy",
    // title: titleCase(
    //   [el.commodity, el.variety]
    //     .filter(Boolean)
    //     .map((el) => el.toLowerCase())
    //     .join(`, `)
    // ),
    // quantityText: el?.qty_mt,
    // priceText: `₹${el.per_mt_price}`,
    type: el?.type,
    image:
      el?.image_link?.length > 0 ? el?.image_link?.[0] : el.commodity_image,
    address: titleCase(
      el?.address_lang ? el?.address_lang : el.address.toLowerCase()
    ),
    day: el?.buyer_listing_time_ago,
    aryaCertified:
      type == "buy" ? false : el?.arya_warehouse == 1 ? true : false,
    rows: [
      titleCase(
        [el?.commodity_lang ? el.commodity_lang : el.commodity, el.variety]
          .filter(Boolean)
          .map((el) => el.toLowerCase())
          .join(`, `)
      ),
      `${parseFloat(el?.qty_mt)} ${BaleOrMT(el.commodity)}`,
      `${
        translation !== null ? translation("Price") : "Price"
      } ${`₹${parseFloat(el.per_mt_price).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
      })}/${BaleOrMT(el.commodity)}`}`,
    ],
  }));
};
export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};
export const date2str = (x, y) => {
  var z = {
    M: x.getMonth() + 1,
    d: x.getDate(),
    h: x.getHours(),
    m: x.getMinutes(),
    s: x.getSeconds(),
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
    return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
  });

  return y.replace(/(y+)/g, function (v) {
    return x.getFullYear().toString().slice(-v.length);
  });
};

export const getMockAPI = async () => {
  return await axios.get("https://jsonplaceholder.typicode.com/todos/1");
};
export const postMockAPI = async (data) => {
  return await axios.post(
    "https://jsonplaceholder.typicode.com/posts/1/comments",
    data
  );
};
export const isArrayNotEmpty = (data) => {
  return Array.isArray(data) && data.length !== 0;
};
export const addComma = (number) => {
  return parseFloat(number).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });
};

export const handleChange = (
  e,
  callFunction,
  setBase64 = "",
  callFunction2 = ""
) => {
  var fileList = e.target.files;
  let type_temp = fileList[0].name.split(".");
  let filesize = fileList[0].size;
  let type = type_temp[type_temp.length - 1];
  let extention = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"];
  if (extention.includes(type) && filesize < 2048000) {
    var filecontainer = [];
    for (var i = 0; i < fileList.length; i++) {
      var file = fileList[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (setBase64) {
          setBase64(reader.result);
          callFunction2(reader.result);
        }
        filecontainer.push(reader.result);
        if (filecontainer.length === fileList.length) {
          uploadImages(filecontainer, callFunction);
        }
      };
      reader.onerror = function (error) {
        toastr.error("please upload valid file");
        // setLoader(false);
      };
    }
  } else {
    toastr.warning("Please Upload Valid Image and Max Size Should be 2MB");
    // setLoader(false);
  }
};

export const commodityFilterChange = (selectedArray) => {
  let dataObj = [{ commodity: [] }];
  selectedArray.forEach((element) => {
    dataObj[0].commodity.push(element.commodity);
  });
  return dataObj;
};
export const commodityFilterChangeForDtr = (selectedArray) => {
  let dataObj = [{ commodities: [] }];
  selectedArray.forEach((element) => {
    dataObj[0].commodities.push(element.commodity);
  });
  return dataObj;
};
const uploadImages = async (files, callFunction) => {
  // setLoader(true);
  let imgarr = [];
  let uploadeds3link = [];
  for (var i = 0; i < files.length; i++) {
    var fileobj = {
      path: "arya/warehouseRequests/",
      file: files[i],
    };
    var apiresponse = await FileUploadService.fileUloadService(fileobj);
    if (apiresponse !== null && apiresponse.data.data.file_path) {
      var objimg = {
        src: apiresponse.data.data.file_path,
        thumbnail: apiresponse.data.data.file_path,
        thumbnailWidth: 100,
        thumbnailHeight: 100,
      };
      imgarr.push(objimg);
      uploadeds3link.push(apiresponse.data.data.file_path);
    }
    // setLoader(false);
  }
  callFunction(uploadeds3link[0]);
};

export const validationFunction = (
  data,
  rulesFunction,
  callBackFunction,
  params = ""
) => {
  let errobj = {};
  errobj = Validation(data, rulesFunction(params));
  callBackFunction(errobj);
  let flag = 0;
  Object.keys(errobj).forEach((index) => {
    if (errobj[index] !== "") {
      flag = 1;
    }
  });
  return flag;
};
export const capitalize = (str, lower = true) => {
  if (str) {
    return (lower ? str.toLowerCase() : str).replace(
      /(?:^|\s|["'([{])+\S/g,
      (match) => match.toUpperCase()
    );
  } else return "";
};

export const checkFileType = (file) => {
  const imageExt = ["jpg", "jpeg", "gif", "png"];
  const fileExt = ["pdf"];
  if (file) {
    const fileExtArr = file.name.split(".");
    const getFileExt = fileExtArr[fileExtArr.length - 1].toLowerCase();
    if (imageExt.includes(getFileExt)) {
      return { ext: getFileExt, status: true, fileType: "image" };
    } else if (fileExt.includes(getFileExt)) {
      return { ext: getFileExt, status: true, fileType: "file" };
    } else {
      return { status: false };
    }
  }
};

export const convertFiletoBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const addCommasToNumber = (number) => {
  return parseFloat(number).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });
};

export const BaleOrMT = (commodity) => {
  return commodity?.toUpperCase() == COTTON_BALES ? "Bales" : "MT";
};

export const downloadFileLink = (link) => {
  let ele = document.createElement("a");
  ele.href = link;
  ele.target = "_blank";
  ele.click();
};

const getCookie = (user) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (user == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const isPWA = (callBack) => {
  if (getQueryParam("in-app") == 1) {
    // localStorage.setItem("appShow", 1);
    const d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = "appShow=1;" + expires + ";path=/";
    callBack(true);
  } else if (getCookie("appShow")) {
    callBack(true);
  }
};

export const scroll = (setscrollX, scrollRef, setscrollEnd) => {
  setscrollX(scrollRef.current.scrollLeft);
  const scrollLeftValue = scrollRef.current.scrollLeft;
  if (
    Math.floor(scrollRef.current.scrollWidth - scrollLeftValue) <=
    scrollRef.current.offsetWidth
  ) {
    setscrollEnd(true);
  } else {
    setscrollEnd(false);
  }
};

export const scrollRight = (
  scrollRef,
  scrollValue,
  scrollX,
  setScrollX = () => null,
  setScrollEnd = () => null
) => {
  scrollRef.current.scrollLeft += scrollValue;
  const scrollRightValue = scrollX + scrollValue;
  setScrollX(scrollX + scrollValue);
  if (
    Math.floor(scrollRef.current.scrollWidth - scrollRightValue) <=
    scrollRef.current.offsetWidth
  ) {
    setScrollEnd(true);
  } else {
    setScrollEnd(false);
  }
};
export const scrollLeft = (
  scrollRef,
  scrollValue,
  scrollX,
  setScrollX = () => null,
  setScrollEnd = () => null
) => {
  scrollRef.current.scrollLeft -= scrollValue;
  setScrollX(scrollX - scrollValue);
  const scrollLeftValue = scrollX - scrollValue;
  if (
    Math.floor(scrollRef.current.scrollWidth - scrollLeftValue) <=
    scrollRef.current.offsetWidth
  ) {
    setScrollEnd(true);
  } else {
    setScrollEnd(false);
  }
};
export const maskNumber = (number) => {
  const maskedPart = "*".repeat(Math.max(0, number.toString().length - 4));
  const maskedNumber = maskedPart + number.toString().slice(-4);
  return maskedNumber;
};

export const checkTypedIfRupee = (text = "") => {
  if (text?.trim() == "") return false;
  if (isNaN(text) == false) return true;
};
const WHATSAPP_ORIGIN_KEYS = {
  KEY1: "whatsapp",
  KEY2: "whatsapp_self",
};
export const isFromWhatsappOrigin = (status) => {
  if (!status) return false;
  return (
    status == WHATSAPP_ORIGIN_KEYS.KEY1 || status == WHATSAPP_ORIGIN_KEYS.KEY2
  );
};

export const getPanFromGst = (text = "") => {
  if (text !== "") {
    let trimText = text?.trim();
    let firstTextRemove = trimText?.slice(2, -3).trim();
    return firstTextRemove;
  } else {
    return false;
  }
};
export const validateGST = (gstin) => {
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return gstRegex.test(gstin);
};

export const BackToTopFunc = () => {
  const scrollDuration = 300; // Duration in milliseconds
  const scrollStep = -window.scrollY / (scrollDuration / 15);

  const scrollInterval = setInterval(() => {
    if (window.scrollY !== 0) {
      window.scrollBy(0, scrollStep);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
};

export const signedUrlFromDoms = async (file, setErrorCallBack = () => {}) => {
  // Utility function to validate file size
  const validateFileSize = (file, minSize, maxSize) => {
    if (file.size > maxSize) {
      return `File size must be less than or equal to ${Math.round(
        maxSize / 1048576
      )} MB`;
    } else if (file.size < minSize) {
      return `File size must be equal to or greater than ${Math.round(
        minSize / 1024
      )} KB`;
    }
    return null;
  };

  // Function to process a single file
  const processSingleFile = async (file) => {
    const payload = {
      contentType: file?.type,
      originalFileName: file?.name,
    };

    try {
      const apiresponse = await generateSignedUrl(payload);

      if (!apiresponse?.data?.payload?.signedUrl) {
        throw new Error("Failed to generate signed URL");
      }

      const { signedUrl, minAllowedUploadBytes, maxAllowedUploadBytes } =
        apiresponse.data.payload;

      // Validate file size
      const errorText = validateFileSize(
        file,
        minAllowedUploadBytes,
        maxAllowedUploadBytes
      );
      if (errorText) {
        setErrorCallBack(errorText);
        return null;
      }

      // Prepare form data for upload
      const formData = new FormData();
      formData.append("contentType", file.type);
      formData.append("originalFileName", file.name);
      formData.append("url", signedUrl);
      formData.append("minAllowedUploadBytes", minAllowedUploadBytes);
      formData.append("maxAllowedUploadBytes", maxAllowedUploadBytes);
      formData.append("fileData", file);

      const apiresponse2 = await saveDocThroughDoms(formData);

      if (apiresponse2?.status === "success") {
        return signedUrl;
      } else {
        throw new Error(apiresponse2?.message || "Failed to save document");
      }
    } catch (error) {
      setErrorCallBack(error.message);
      return null;
    }
  };

  // Main logic
  if (Array.isArray(file)) {
    const results = await Promise.all(file.map(processSingleFile));
    return results.filter(Boolean); // Filter out any null results (i.e., failed uploads)
  } else {
    return await processSingleFile(file);
  }
};

export const signedUrlFromDoms2 = async (file, setErrorCallBack = () => {}) => {
  const payload = {
    contentType: file.type,
    originalFileName: file.name,
  };
  var apiresponse = await generateSignedUrl(payload);
  if (apiresponse?.data?.payload?.signedUrl) {
    if (
      Number(file.size) >
      Number(apiresponse?.data?.payload?.maxAllowedUploadBytes)
    ) {
      let errorText = `File size must be less than or equal to ${Math.round(
        parseInt(apiresponse?.data?.payload?.maxAllowedUploadBytes) / 1048576
      )} MB`;
      setErrorCallBack(errorText);

      return;
    } else if (
      Number(file.size) <
      Number(apiresponse?.data?.payload?.minAllowedUploadBytes)
    ) {
      let errorText = `File size must be equal to or greater than ${Math.round(
        parseInt(apiresponse?.data?.payload?.minAllowedUploadBytes) / 1024
      )} KB`;
      setErrorCallBack(errorText);
      return;
    }
    const formData = new FormData();
    const payload2 = {
      contentType: file.type,
      originalFileName: file.name,
      url: apiresponse?.data?.payload?.signedUrl,
      minAllowedUploadBytes: apiresponse?.data?.payload?.minAllowedUploadBytes,
      maxAllowedUploadBytes: apiresponse?.data?.payload?.maxAllowedUploadBytes,
      fileData: file,
    };
    (function () {
      for (const key in payload2) {
        formData.append(key, payload2[key]);
      }
    })();
    var apiresponse2 = await saveDocThroughDoms(formData);
    if (apiresponse2?.status == "success") {
      return apiresponse?.data?.payload?.signedUrl;
    } else {
      let errorText = apiresponse2?.message;
      setErrorCallBack(errorText);
      return;
    }
  } else {
    setErrorCallBack("");
    return;
  }
};

export const downloadFile = async (file_id) => {
  const payload = {
    documentId: file_id,
  };
  var apiresponse = await downloadDocThroughDoms(payload);
  if (apiresponse?.data?.payload?.document) {
    window.open(apiresponse?.data?.payload?.document, "_blank");
  }
};
