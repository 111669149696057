import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { encryptData, isMobile } from "../helperFunc";
import s from "./GstDetailForm/style.module.css";
import BillingInfoForm from "./BillingInfoForm/BillingInfoForm";
import { authServices } from "./../../../../_services/Auth/AuthServices";
import {
  getPanFromGst,
  titleCase,
  validateGST,
} from "./../CommonFunctions/Function";
import Button from "./../CommonComponents/Button";
import { getCityStateFromPincode } from "./../../../../_services/Arya2.0/CommonServices";
import useAllUserData from "./../CustomHooks/useAllUserData";
import { postUserGstDetails } from "./../../../../_services/Arya2.0/AccountSettings";
import ValidationError from "./../CommonComponents/ValidationError/ValidationError";
import { customToastr } from "./../CustomToastr";
const INTITIAL_FORM_VALUE = {
  gstin: "",
  pan: "",
  entity_name: "",
  gst_state: "",
  trade_name: "",
  pincode: "",
  address: "",
  state_name: "",
  city: "",
  city_id: "",
  state_id: "",
  address: "",
  gstVerify: false,
  trade_name: "",
};
const INITIAL_CONTACT_PERSON = [
  {
    name: "",
    phone: "",
    email: "",
    designation: "",
    department: "",
    primary: true,
    receiveBilling: true,
  },
];

const UpdateBillingForm = ({
  pincode,
  city,
  state,
  permanantAddressLine1,
  billingName,
  tradeName,
  isNewGst = true,
  gstContactPersonDetails,
  getSelectGstContactPerson,
  setUpdateContactPerson,
  gstNumber,
  panNumber,
  gstState,
}) => {
  const history = useHistory();
  const allUserData = useAllUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(INTITIAL_FORM_VALUE);
  const [errors, setErrors] = useState(INTITIAL_FORM_VALUE);
  const [contactPersonForm, setContactPersonForm] = useState([]);
  const [isDisableForm, setIsDisableForm] = useState(true);
  const [apiError, setApiError] = useState("");
  const handleForm = (e) => {
    const { name, value } = e?.target;

    setFormValue((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["pincode"]: pincode };
    });
  }, [pincode]);

  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["state"]: state };
    });
  }, [state]);

  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["city"]: city };
    });
  }, [city]);

  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["address"]: permanantAddressLine1 };
    });
  }, [permanantAddressLine1]);
  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["billing_name"]: billingName };
    });
  }, [billingName]);

  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["entity_name"]: tradeName };
    });
  }, [tradeName]);
  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["gstin"]: gstNumber };
    });
  }, [gstNumber]);
  useEffect(() => {
    setFormValue((prev) => {
      return { ...prev, ["pan"]: gstNumber };
    });
  }, [panNumber]);
  useEffect(() => {
    setFormValue((prev) => {
      return {
        ...prev,
        ["gst_state"]: gstState ? titleCase(gstState) : "",
      };
    });
  }, [gstState]);

  const handleGstVerify = async () => {
    if (
      formValue?.gstin &&
      formValue?.gstin.length === 15 &&
      validateGST(formValue?.gstin)
    ) {
      setIsLoading(true);
      let dataObj = {
        gstin_number: formValue?.gstin,
      };
      let response = await authServices.gstVerification(dataObj, "?v3=1");
      setIsLoading(false);
      if (
        response?.data?.data?.success_status == "1" &&
        response?.data?.data?.name
      ) {
        setFormValue((prev) => ({
          ...prev,
          ["gstin"]: response.data.data.gstin,
          ["entity_name"]: response.data.data.name,
          gstVerify: true,
          ["gst_state"]: response.data.data.principalPlaceState
            ? titleCase(response.data.data.principalPlaceState)
            : "",
          ["pan"]: getPanFromGst(formValue?.gstin),
        }));
        // props.setCompletedStep(stepCounter);
        // props.setCurrentStep(stepCounter);
        // setDisable(false);
        setErrors((prev) => {
          return { ...prev, ["gstin"]: "" };
        });
      } else {
        setErrors((prev) => ({
          ...prev,
          ["gstin"]: response?.data?.message,
        }));
      }
    } else {
      setErrors((prev) => {
        return { ...prev, ["gstin"]: "Please Enter Valid GST Number" };
      });
    }
  };
  const handlePincode = (e) => {
    setErrors(INTITIAL_FORM_VALUE);

    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setFormValue((prev) => ({
        ...prev,
        pincode: value,
      }));
    } else {
    }
  };

  const validationRules = [
    {
      field_name: "gstin",
      label: "GST",
      type: "string",
      isRequired:
        formValue?.gstin?.length > 0 && formValue?.gstVerify ? false : true, // dynamic requirement
      max: 15,
      errorMsg: "Enter a valid GST number",
    },
    {
      field_name: "pincode",
      label: "Pincode",
      type: "number",
      isRequired: (formValue) => formValue?.pincode?.length > 0, // dynamic requirement
      errorMsg: "Enter a valid Pincode",
      min: 6,
      max: 6,
    },
    {
      field_name: "city",
      label: "City",
      type: "string",
      isRequired: true, // always required
      errorMsg: "City is required",
    },
    {
      field_name: "state_name",
      label: "State",
      type: "string",
      isRequired: true, // always required
      errorMsg: "State is required",
    },
    {
      field_name: "pan",
      label: "Pan",
      type: "string",
      isRequired: true, // always required
      errorMsg: "Pan is required",
    },
  ];

  const Validation = (formValue, rules) => {
    const errors = {};

    rules.forEach((rule) => {
      const { field_name, isRequired, max, min, errorMsg } = rule;
      const value = formValue[field_name];

      // Check if the field is required (dynamic or static)
      const required =
        typeof isRequired === "function" ? isRequired(formValue) : isRequired;

      if (required && (!value || value.trim() === "")) {
        errors[field_name] = errorMsg || `${rule.label} is required.`;
      } else if (max && value?.length > max) {
        errors[
          field_name
        ] = `${rule.label} must be at most ${max} characters long.`;
      } else if (min && value?.length < min) {
        errors[
          field_name
        ] = `${rule.label} must be at least ${min} characters long.`;
      }
    });

    return errors;
  };
  useEffect(() => {
    const errobj = Validation(formValue, validationRules);
    setErrors(errobj);
    // Check if there are no errors
    if (
      Object.values(errobj).filter(Boolean).length === 0 &&
      contactPersonForm.length > 0
    ) {
      setIsDisableForm(false);
    } else {
      setIsDisableForm(true);
    }
  }, [formValue, contactPersonForm]);

  useEffect(() => {
    setUpdateContactPerson(contactPersonForm);
  }, [contactPersonForm]);
  useMemo(async () => {
    if (formValue?.pincode?.length === 6) {
      const res = await getCityStateFromPincode(`/${formValue.pincode}`);
      if (res?.status_code === 200) {
        setFormValue((prev) => ({
          ...prev,
          city: res.data.city_name,
          city_id: res.data.city_id,
          state_name: res?.data.state_name,
          state_id: res.data.state_id,
        }));
      } else {
        setFormValue((prev) => ({
          ...prev,
          city: "",
          state_name: "",
          city_id: "",
          state_id: "",
        }));
      }
    } else {
      setFormValue((prev) => ({
        ...prev,
        city: "",
        state_name: "",
        city_id: "",
        state_id: "",
      }));
    }
  }, [formValue.pincode]);

  const handleSaveGST = async () => {
    setApiError("");
    const newContactPersonForm = contactPersonForm.map((item) => ({
      ...item,
      isPrimary: item.isPrimary.toString().toUpperCase(),
      billingCommunication: item.billingCommunication.toString().toUpperCase(),
    }));
    const datObj = {
      name: allUserData?.member?.entity_name,
      email: allUserData?.member?.email,
      mobile: allUserData?.member?.phone_number,
      pan_number: encryptData(formValue.pan),
      gstin_number: encryptData(formValue?.gstin),
      address: formValue?.address,
      state: formValue.state_id,
      district: formValue.city_id,
      pincode: formValue.pincode,
      user_type: allUserData?.member?.user_type,
      aadhar_number: "",
      contactDetails: [
        {
          name: formValue.entity_name,
          status: "ACTIVE",
          billingName: formValue.entity_name,
          address: {
            addressLine1: formValue?.address,
            state: formValue.state_id,
            district: formValue.city_id,
            pincode: formValue.pincode,
          },
          contactPerson: [...newContactPersonForm],
        },
      ],
    };
    setIsLoading(true);
    const saveGstResponse = await postUserGstDetails(datObj);
    setIsLoading(false);

    if (saveGstResponse.status_code === 200) {
      if (saveGstResponse?.data.success_status == 0) {
        setApiError(saveGstResponse?.message);
      }
      if (
        saveGstResponse?.status == "success" &&
        saveGstResponse?.message == "Your KYC Done Successfully"
      ) {
        customToastr({
          text: "GST added Successfull",
          type: "success",
        });
        history.push("/manage-profile/general-details");
      }
    }

    if (saveGstResponse.status_code == 422) {
      setApiError(saveGstResponse?.message[0]);
    }
  };

  const handleUpdateGst = async () => {};

  return (
    <div>
      <div className={`${isMobile ? "margin-top24" : s["mt-32"]}`}>
        <BillingInfoForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          formValue={formValue}
          setFormValue={setFormValue}
          contactPersonForm={contactPersonForm}
          setContactPersonForm={setContactPersonForm}
          handlePincode={handlePincode}
          handleForm={handleForm}
          isNewGst={false}
          gstContactPersonDetails={gstContactPersonDetails}
        />
      </div>
      {apiError && (
        <div className="margin-top12">
          <ValidationError
            imageClassName={s["imageClassName"]}
            text={apiError}
          />
        </div>
      )}

      <div
        className={`d-flex justify-content-center ${
          isMobile ? "margin-bottom24" : `${isNewGst && s["mb-60"]}`
        }`}
      >
        {isNewGst && (
          <Button
            onClick={handleSaveGST}
            className={`${isDisableForm && `${s["grayButton"]}`} 
              ${isMobile ? s["mobAddButton"] : s["addButton"]}`}
            title="Save"
            disabled={isDisableForm}
          />
        )}
      </div>
    </div>
  );
};
export default UpdateBillingForm;
