import React, { useState } from "react";
import HeadLines from "../CommonComponents/HeadLines";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import { errorInfoIcon } from "../icon_assets";
import { KycFormData } from "./Constant";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import UploadDocumentComponent from "../Finance/Components/UploadFile/UploadDocumentComponent";
import MultiLanguageTranslate from "../../../../_components/Translate/MultiLangTranslate";
import { isMobile } from "../helperFunc";
import eyeIcon from "./../DesktopImages/Home/eye.svg";
import darkeye from "./../DesktopImages/Home/darkeye.svg";
import eyeCutIcon from "./../DesktopImages/Home/eyeCutIcon.svg";

import { fileSizeMax } from "../../../../_constants/Config/AryaConstants";
import CustomRadioButton from "../../../../_components/TradeContract/CustomRadioButton/CustomRadioButton";
const KycForm = (props) => {
  const { t: translation } = useTranslation();

  const [kycDoc, setKycDoc] = useState(props.type === "farmer" ? "Pan" : "Gst");
  const [aadhaarFrontImage, setAadhaarFrontImage] = useState("");
  const [panFrontImage, setPanFrontImage] = useState("");
  const [gstFrontImage, setGstFrontImage] = useState("");
  const [aadhaarFrontImageBase64, setAadhaarFrontImageBase64] = useState("");
  const [imageName, setImageName] = useState({});
  const [errorAt, setErrorAt] = useState("");
  const [showKycText, setShowKycText] = useState(false);

  const [hasKycData, setHasKycData] = useState({
    hasKyc: "yes",
  });

  const handleRadioChange = (e) => {
    const value = e?.target?.value || "yes";
    setKycDoc(
      value === "yes"
        ? props.type === "farmer"
          ? "Pan"
          : "Gst"
        : props.type === "farmer"
        ? "Aadhar"
        : "Pan"
    );

    setHasKycData((prev) => ({
      ...prev,
      hasKyc: value,
    }));

    props.setData((prev) => ({
      ...prev,
      name: "",
      Gst: "",
      Pan: "",
      Aadhar: "",
      aadharDoc: "",
    }));
  };

  const callBackFunction = (image) => {
    if (kycDoc == "Aadhar") setAadhaarFrontImage(image);
    else if (kycDoc == "Pan") setPanFrontImage(image);
    else setGstFrontImage(image);

    props.setData((prev) => ({
      ...prev,
      [kycDoc == "Aadhar"
        ? "aadharDoc"
        : kycDoc == "Pan"
        ? "panDoc"
        : "gstDoc"]: image,
    }));
  };

  // const callBackFunction2 = (image) => {
  //   props.setData((prev) => ({
  //     ...prev,
  //     ["aadharDocBase64"]: image,
  //   }));
  // };

  console.log(props.data, "props.data");

  const handleImageChange = (event) => {
    // window.aaa = event.target.files;
    const a = event.target.files;
    props.setData((prev) => ({
      ...prev,
      ["aadharDoc"]: a?.[0] || {},
    }));
    setImageName(a?.[0]);
  };
  const handleMsmeChange = (event) => {
    const file = event?.target?.files[0];

    if (file && event.target.name === "msme") {
      const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes

      // Allowed MIME types for images and PDFs
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];

      if (!allowedTypes.includes(file.type)) {
        props.setMSMEError("Only images (JPEG, PNG) or PDF files are allowed.");
        return; // Exit the function to prevent further processing
      }

      if (file.size > maxFileSize) {
        props.setMSMEError("File size should not exceed 2 MB.");
        return; // Exit the function to prevent further processing
      }

      // Set the file to the state
      props.setData((prev) => ({
        ...prev,
        ["udyamCertificate"]: file ? file : null,
      }));
    } else {
      console.error("No file selected.");
    }
  };

  // const handleChange = (e, callFunction, setBase64) => {
  //   var fileList = e.target.files;
  //   let type_temp = fileList[0].name.split(".");
  //   let filesize = fileList[0].size;
  //   let type = type_temp[type_temp.length - 1];
  //   let extention = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"];
  //   if (extention.includes(type) && filesize < 2048000) {
  //     var filecontainer = [];
  //     for (var i = 0; i < fileList.length; i++) {
  //       var file = fileList[i];
  //       let reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => {
  //         if (setBase64) {
  //           setBase64(reader.result);
  //           props.setData((prev) => ({
  //             ...prev,
  //             ["aadharDocBase64"]: reader.result,
  //           }));
  //         }
  //         filecontainer.push(reader.result);
  //         if (filecontainer.length === fileList.length) {
  //           uploadImages(filecontainer, callFunction);
  //         }
  //       };
  //       reader.onerror = function (error) {
  //         toastr.error("please upload valid file");
  //         // setLoader(false);
  //       };
  //     }
  //   } else {
  //     toastr.warning("Please Upload Valid Image and Max Size Should be 2MB");
  //     // setLoader(false);
  //   }
  // };

  // const uploadImages = async (files, callFunction) => {
  //   // setLoader(true);
  //   let imgarr = [];
  //   let uploadeds3link = [];
  //   for (var i = 0; i < files.length; i++) {
  //     var fileobj = {
  //       path: "arya/warehouseRequests/",
  //       file: files[i],
  //     };
  //     var apiresponse = await FileUploadService.fileUloadService(fileobj);
  //     if (apiresponse !== null && apiresponse.data.data.file_path) {
  //       var objimg = {
  //         src: apiresponse.data.data.file_path,
  //         thumbnail: apiresponse.data.data.file_path,
  //         thumbnailWidth: 100,
  //         thumbnailHeight: 100,
  //       };
  //       imgarr.push(objimg);
  //       uploadeds3link.push(apiresponse.data.data.file_path);
  //
  //     }
  //     // setLoader(false);
  //   }
  //   callFunction(uploadeds3link[0]);
  //   props.setData((prev) => ({
  //     ...prev,
  //     [kycDoc == "Aadhar"
  //       ? "aadharDoc"
  //       : kycDoc == "Pan"
  //       ? "panDoc"
  //       : "gstDoc"]: uploadeds3link[0],
  //   }));
  //
  // };

  const fishEyeIcon = (
    <>
      <img
        src={
          props.data[kycDoc] ? (showKycText ? eyeCutIcon : darkeye) : eyeIcon
        }
        onClick={() => setShowKycText(!showKycText)}
        className={`${style["positionEyeCSS"]} positionAbsolute cursorPointer`}
        height={isMobile ? "18" : "20"}
        width={isMobile ? "18" : "20"}
        alt=""
      />
    </>
  );

  return (
    <div
      className={`margin-left16 margin-right16 ${
        isMobile ? "" : "padding-top8"
      }`}
    >
      {props.type != "banker" && (
        <HeadLines
          className={`font-weight400 ${
            isMobile
              ? "font-size12 margin-bottom16 line-height14"
              : "margin-bottom24 font-size16 line-height19"
          } letterSpacing025  `}
          text="Please select any one of the ID proof and upload the required document"
        />
      )}

      <div>
        <HeadLines
          text={
            props.type === "farmer"
              ? "Do you have PAN Number?"
              : "Do you have GST Number?"
          }
          className={`font-weight400 font-size16 line-height19 textColorBlack letterSpacing025`}
        />
        <div className="d-flex align-items-center gap24 padding-top8 padding-bottom20">
          <div>
            <CustomRadioButton
              radioBtnProps={{
                name: "hasKyc",
                checked: hasKycData.hasKyc === "yes",
                value: "yes",
                onChange: handleRadioChange,
              }}
              className={"radioBtn"}
            />
            <label htmlFor="hasPan" className="m-0">
              <HeadLines text="Yes" className="padding-left8" />
            </label>
          </div>

          <div>
            <CustomRadioButton
              radioBtnProps={{
                name: "hasKyc",
                checked: hasKycData.hasKyc === "no",
                value: "no",
                onChange: handleRadioChange,
              }}
            />
            <label htmlFor="hasPan" className="m-0">
              <HeadLines text="No" className="padding-left8" />
            </label>
          </div>
        </div>
      </div>
      {/* 
      <div className="d-flex">
        {KycFormData.map((item, index) => {
          if (
            (props.type == "farmer" &&
              (item.label == "Aadhar" || item.label == "Pan")) ||
            (props.type == "banker" && item.label == "Gst") ||
            (props.type == "others" &&
              (item.label == "Pan" || item.label == "Gst"))
          ) {
            return (
              <>
                <div
                  onClick={() => {
                    setKycDoc(item.label);
                  }}
                  className={`cursorPointer boxRadius6 ${
                    kycDoc == item.label
                      ? style["selectedBorder"]
                      : "light2Border"
                  } ${
                    isMobile
                      ? ` ${style["margin-bottom14"]} margin-right16`
                      : ` margin-bottom24 margin-right24`
                  }`}
                >
                  <div
                    className={`d-flex textCenter  ${
                      style[item.label + "_Background"]
                    } boxRadius6`}
                  >
                    <img
                      src={item.image}
                      className={` ${style[item.label + "_Height"]} width-100 `}
                    />
                  </div>
                  <HeadLines
                    text={item.label}
                    className={`${
                      props.userType == item.label ? "colorAccent4" : ""
                    } textCenter padding4 ${
                      isMobile
                        ? "font-size12 line-height14"
                        : " font-size16 line-height19 colorAccent4"
                    } font-weight500  letterSpacing025 ${
                      props.type == "farmer" ? "" : style["txt-uppercase"]
                    }`}
                  />
                </div>
              </>
            );
          } else {
            return "";
          }
        })}
      </div> */}
      <div className={isMobile ? "" : "d-flex gap24"}>
        {kycDoc == "Aadhar" ? (
          <div className={isMobile ? "" : "width-100"}>
            <UploadDocumentComponent
              className={`${isMobile ? "margin-top16" : ""} width-100`}
              customTextClassName={`${style["text-truncate-file-upload"]} text-truncate`}
              onChange={(e) => {
                handleImageChange(e);
              }}
              value={imageName?.name}
              placeholder={imageName?.name || "Select Aadhar"}
              inputClassName={`width-100 boxRadius4 padding12 ${
                style["input-border"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16 letterSpacing001 margin-top8 "
                  : "font-size16 line-height19 letterSpacing05 margin-top4"
              }`}
              inputParentClassName={
                isMobile ? " margin-top8  " : " margin-top4 "
              }
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style"}
              title={"Upload Aadhar Card"}
              showError={""}
              errorIcon={errorInfoIcon}
              titleClassName={`${
                isMobile
                  ? `font-size14 line-height16 font-weight500 ${style["color333333"]}`
                  : "font-size16 line-height19 font-weight600 colorDark9"
              } letterSpacing05`}
              compulsoryStarClassName={style["margin-top4px"]}
              isCompulsory={true}
              financePage={false}
              kycForm={true}
            />

            {props?.error && props.error["aadharDoc"] ? (
              <div className="d-flex align-items-start padding-top4">
                {errorInfoIcon && (
                  <img
                    src={errorInfoIcon}
                    className={`${style["errorCSS"]} ${
                      style["error-icon-style"]
                    } display-inline-block  ${
                      isMobile ? "" : style["width18px"]
                    }`}
                    alt=""
                  />
                )}
                <HeadLines
                  className={`${
                    style["errorCSS"]
                  } input-error-style font-size12 display-inline-block padding-left4 ${
                    isMobile ? "" : `${style["desktopCssImp"]} `
                  }`}
                  text={
                    translation(`${props.error["aadharDoc"]}`)

                    // <>
                    //   {errorIcon && (
                    //     <img
                    //       src={errorIcon}
                    //       className={`${errorIconClassName}`}
                    //       alt=""
                    //     />
                    //   )}
                    //   {showError}
                    // </>
                  }
                ></HeadLines>
              </div>
            ) : (
              ""
            )}

            {/* <UploadDoc
            titleClassName={`${style["color333333"]} font-weight500 font-size14 line-height16 letterSpacing05`}
            title={
              "Upload " +
              (kycDoc != "Aadhar" ? kycDoc.toUpperCase() : kycDoc) +
              " Card"
            }
            inputClassName={`width-100 boxRadius4 padding12 ${style["inputBorder"]} font-weight400 font-size14 line-height16 letterSpacing001`}
            inputParentClassName=" margin-top8  "
            compulsoryStarClassName={style["margin-top4px"]}
            isCompulsory={true}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style"}
            showError={props?.error ? props.error["aadharDoc"] : ""}
            errorIcon={errorInfoIcon}
            value=""
            name="documents"
            onChange={(e) => {
              handleImageChange(e);
            }}
          /> */}
            <HeadLines
              text={`Please upload a jpeg/png/pdf. Maximum file size limit is ${fileSizeMax}MB.`}
              className={` font-weight400 ${
                isMobile
                  ? "font-size10 line-height12 margin-top16 "
                  : "font-size14 line-height16 margin-top8 "
              } letterSpacing025 colorDark7`}
            />
          </div>
        ) : (
          ""
        )}

        <div className="width-100">
          <InputFieldWithTitle
            titleClassName={`  ${
              isMobile
                ? `font-size14 line-height16 font-weight500 ${style["color333333"]}`
                : "font-size16 line-height19 font-weight600 colorDark9"
            } letterSpacing05`}
            title={
              "Enter " +
              (kycDoc != "Aadhar" ? kycDoc.toUpperCase() : kycDoc) +
              " Number"
            }
            disabled={false}
            isCompulsory={true}
            inputClassName={`width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400  ${
              isMobile
                ? "font-size14 line-height16 letterSpacing001 margin-top8 "
                : "font-size16 line-height19 letterSpacing05 margin-top4 "
            } ${showKycText ? "" : style["pox-password"]}`}
            inputParentClassName=""
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top8"}
            showError={props?.error ? props.error[kycDoc] : ""}
            errorIcon={errorInfoIcon}
            value={props.data[kycDoc]}
            name={kycDoc}
            onChange={props.onChange}
            compulsoryStarClassName={style["margin-top4px"]}
            className="width-100"
            verifyText={fishEyeIcon}
            showVerifyText={true}
            marginCSS="margin-bottom8"
          />
          <HeadLines
            text="VERIFY"
            className={`${style["z100 "]} positionRelative   
              ${
                props.disable
                  ? "colorSecondary8"
                  : "cursor-pointer colorGreen269"
              } ${
              isMobile
                ? "text-align-right font-weight600 font-size12 line-height14"
                : "text-align-right font-weight600 font-size14 line-height16"
            }
               ${props?.error?.[kycDoc] ? style["mtmin30"] : ""}
            `}
            onClick={
              !props.disable
                ? () => {
                    props.setDocChosen(kycDoc);
                    props.verifyClick(kycDoc);
                  }
                : () => {}
            }
          />
        </div>
      </div>
      <InputFieldWithTitle
        titleClassName={`  ${
          isMobile
            ? `font-size14 line-height16 font-weight500 ${style["color333333"]}`
            : "font-size16 line-height19 font-weight600 colorDark9"
        } letterSpacing05`}
        title={`Name (as per ${kycDoc})`}
        disabled={true}
        isCompulsory={true}
        inputClassName={`width-100 boxRadius4 padding12 ${
          style["inputBorder"]
        } font-weight400  ${
          isMobile
            ? "font-size14 line-height16 letterSpacing001 margin-top8 "
            : "font-size16 line-height19 letterSpacing05 margin-top4 "
        }`}
        inputParentClassName=""
        value={props.data?.name}
        name="nameAsPerDoc"
        compulsoryStarClassName={style["margin-top4px"]}
        className="width-100"
        marginCSS="margin-bottom20 margin-top20"
      />
      {props.data.msme === 1 && (
        <div className={`${isMobile ? "" : "d-flex gap14 w-100"} `}>
          <div className="w-100">
            <UploadDocumentComponent
              className={``}
              customTextClassName={`${style["text-truncate-file-upload"]} text-truncate`}
              onChange={(e) => {
                handleMsmeChange(e);
              }}
              name={"msme"}
              value={props.data.udyamCertificate?.name}
              placeholder={
                props.data?.udyamCertificate?.name ?? "Upload Udyam Certificate"
              }
              inputClassName={`boxRadius4 padding12 ${
                style["input-border"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16 letterSpacing001 "
                  : "font-size16 line-height19 letterSpacing05 margin-top8"
              }`}
              inputParentClassName={isMobile ? " " : "margin-top12 "}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              title={"Upload Udyam Certificate"}
              // showError={
              //   props.error.udyamCertificate ? props.error.udyamCertificate : ""
              // }
              errorIcon={errorInfoIcon}
              titleClassName={`${
                isMobile
                  ? `font-size14 line-height16 font-weight500 ${style["color333333"]}`
                  : "font-size16 line-height19 font-weight600 colorDark9"
              } letterSpacing05`}
              compulsoryStarClassName={style["margin-top4px"]}
              isCompulsory={true}
              financePage={false}
              // kycForm={true}
            />
            <HeadLines
              className={`font-weight400  letterSpacing05 colorDark7 ${
                isMobile
                  ? "margin-top4 font-size12 line-height12"
                  : "margin-top8 font-size14 line-height16"
              }`}
              text="Please upload a jpeg/png/pdf. Maximum file size limit is 2MB."
            />
            {props.msmeError && (
              <div className="d-flex align-items-center padding-top4">
                {errorInfoIcon && (
                  <img
                    src={errorInfoIcon}
                    className={`${style["errorCSS"]} ${
                      style["error-icon-style"]
                    } display-inline-block  ${
                      isMobile ? "" : style["width18px"]
                    }`}
                    alt=""
                  />
                )}
                <HeadLines
                  className={`${
                    style["errorCSS"]
                  } input-error-style font-size12 display-inline-block padding-left4 ${
                    isMobile ? "" : `${style["desktopCssImp"]} `
                  }`}
                  text={translation(`${props.msmeError}`)}
                ></HeadLines>
              </div>
            )}
          </div>

          <div className={`w-100 ${isMobile && "margin-top16"}`}>
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 ${style["color333333"]}`
                  : "font-size16 line-height19 font-weight600 colorDark9"
              } letterSpacing05`}
              title={"Udyam Certificate Number"}
              disabled={false}
              placeholder={"UDYAM-I-JH-00-0123456"}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4   width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16"
                  : "font-size16 line-height19"
              } letterSpacing001 w-100`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={
                props.error.udyamCertificateNumber
                  ? props.error.udyamCertificateNumber
                  : ""
              }
              errorIcon={errorInfoIcon}
              value={props?.data?.udyamCertificateNumber}
              name="udyamCertificateNumber"
              onChange={(e) => {
                const value = e?.target?.value;
                props.setData((prev) => ({
                  ...prev,
                  ["udyamCertificateNumber"]: value,
                }));
              }}
              marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
              // showVerifyText="Verify"
              // showVerifyTextClassName={style["to-verify-email-text"]}
              // rightInputFixedTextClassName={style["to-verify-email-text"]}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default KycForm;
