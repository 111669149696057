import { encryptData } from "../../_Pages/_webapp/arya2.0/helperFunc";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";
import toastr from "toastr";

export const authServices = {
  sendOTPOnMobile,
  verifyOtp,
  register,
  resetOtp,
  panVerification,
  aadhaarVerification,
  registerUserByAdmin,
  verifyOtpByMobile,
  registerUpdateKyc,
  registerUserChild,
  childLogin,
  editUserByAdmin,
  ssoCall,
  addUserType,
  addPreferredLocation,
  gstVerification,
  aryaShaktiMobile,
};

async function sendOTPOnMobile(data) {
  try {
    const response = await RestMethod.POST("/get-otp-mobile", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Login failed. Please try later");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function aryaShaktiMobile(data) {
  try {
    const response = await RestMethod.POST("/aryashakti/verify-mobile", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function verifyOtp(data) {
  try {
    const response = await RestMethod.POST("/verify-otp-mobile", data, {
      withCredentials: true,
    });
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function verifyOtpByMobile(data) {
  try {
    const response = await RestMethod.POST("/verify-by-mobile", data);
    //const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Login Failed. Please try later");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function register(data) {
  try {
    const response = await RestMethod.POST("/register-by-mobile", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function registerUpdateKyc(data) {
  try {
    const response = await RestMethod.POST("/register-update-by-mobile", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function resetOtp(data) {
  try {
    const response = await RestMethod.POST("/resend-otp", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function panVerification(data, params) {
  try {
    data = { ...data, pan: encryptData(data.pan) };
    const response = await RestMethod.POST(
      `/v2/verify-pan${params ? params : ""}`,
      data
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function gstVerification(data, params = "") {
  try {
    data = { ...data, gstin_number: encryptData(data.gstin_number) };
    const response = await RestMethod.POST(
      `/verify-gst${params ? params : ""}`,
      data
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function aadhaarVerification(data) {
  try {
    data = { ...data, aadhar_number: encryptData(data?.aadhar_number) };
    const response = await RestMethod.POST("/verify-aadhar", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function registerUserByAdmin(data) {
  try {
    const response = await RestMethod.POST("/register-by-admin?v3=1", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function editUserByAdmin(data, id) {
  try {
    const response = await RestMethod.PUT("/update-gstn/" + id, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function registerUserChild(data) {
  try {
    const response = await RestMethod.POST("/add-user-child", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function childLogin(data) {
  try {
    const response = await RestMethod.POST("/child-login", data);
    //const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function ssoCall(data) {
  try {
    const response = await RestMethod.POST("/sso", data);
    return response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function addUserType(data) {
  try {
    const response = await RestMethod.POST("/add-user-type", data);
    return response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function addPreferredLocation(data) {
  try {
    const response = await RestMethod.POST("/add-preferred-service", data);
    return response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}
