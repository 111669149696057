import React, { useEffect, useState } from "react";
import Button from "../CommonComponents/Button";
import HeadLines from "../CommonComponents/HeadLines";
import Switch from "../CommonComponents/Switch/Switch";
import { blackInfoIcon, errorInfoIcon } from "../icon_assets";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import s from "./styles.module.css";
import useUserDetails from "../CustomHooks/useUserDetails";
import { isMobile } from "../helperFunc";
import ErrorMessage from "../CommonComponents/ErrorMessage/ErrorMessage";
import BillingInfoForm from "./BillingInfoForm/BillingInfoForm";
import UserInfoForm from "./UserInfoForm/UserInfoForm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import UpdateBillingForm from "./UpdateBillingForm";
import { postUserDetails } from "../../../../_services/Arya2.0/AccountSettings";
import { customToastr } from "../CustomToastr";
const AccountSettingForm = (props) => {
  const {
    onVerifyClick,
    disableSave = true,
    email,
    pincode,
    state,
    city,
    handleEmailChange,
    handlePincode,
    rmName,
    isValidEmailRegex,
    hideDetails,
    permanantAddressLine1,
    permanantAddressLine2,
    handleSwitchChange,
    handleAddressLine1Change,
    handleAddressLine2Change,
    onProfileUpdate,
    accountSettingFormErrors,
    isEmailVerified,
    errorMessage,
    rmEmail,
    rmPhone,
    childEntities = [],
    contactDetails,
    usersData,
    setIsLoading,
    onUserDetailsAPI,
  } = props;
  const history = useHistory();
  const userDetail = useUserDetails();
  const { t: translation } = useTranslation();
  const [gstDetails, setGstDetails] = useState([]);
  const [gstpillSelected, setGstPillSelected] = useState("");
  const [updateContactPerson, setUpdateContactPerson] = useState([]);
  const [isDisableSave, setIsDisableSave] = useState(true);
  const [formError, setFormError] = useState("");
  useEffect(() => {
    if (childEntities?.length) {
      setGstDetails(childEntities);
      setGstPillSelected(childEntities[0]);
    }
  }, [childEntities?.length]);
  useEffect(() => {
    if (updateContactPerson?.length > 0) {
      setIsDisableSave(false);
    }
  }, [updateContactPerson]);

  const handleGSTContactUpdate = async () => {
    const newContactPersonForm = updateContactPerson.map((item) => ({
      ...item,

      isPrimary: item.isPrimary.toString().toUpperCase(),
      billingCommunication: item.billingCommunication.toString().toUpperCase(),
    }));
    const payload = {
      entityId: gstpillSelected?.entityId,
      email: usersData[0]?.personalDetails?.email
        ? usersData[0]?.personalDetails?.email
        : "",
      contactDetails: [
        {
          id: gstpillSelected?.contactDetails
            ? gstpillSelected?.contactDetails[0]["id"]
            : "",
          name: gstpillSelected?.contactDetails
            ? gstpillSelected?.contactDetails[0]["name"]
            : "",
          status: gstpillSelected?.contactDetails
            ? gstpillSelected?.contactDetails[0]["status"]
            : "",
          billingName: gstpillSelected?.contactDetails
            ? gstpillSelected?.contactDetails[0]["billingName"]
            : "",
          address: {
            addressLine1: gstpillSelected?.contactDetails[0].address
              ?.addressLine1
              ? gstpillSelected?.contactDetails[0].address?.addressLine1
              : "",
            state: gstpillSelected?.contactDetails[0].address?.stateId
              ? gstpillSelected?.contactDetails[0].address?.stateId
              : "",
            district: gstpillSelected?.contactDetails[0].address?.districtId
              ? gstpillSelected?.contactDetails[0].address?.districtId
              : "",
            pincode: gstpillSelected?.contactDetails[0].address?.pincode
              ? gstpillSelected?.contactDetails[0].address?.pincode
              : "",
          },
          contactPerson: [...newContactPersonForm],
        },
      ],
    };

    setIsLoading(true);
    const apiResponse = await postUserDetails(payload);
    setIsLoading(false);
    if (apiResponse?.status_code === 200) {
      customToastr({
        text: "Details saved successfully",
        type: "success",

        timeOut: 1,
      });
      onUserDetailsAPI();
      // setIsDisableSave(true);
    } else {
      if (
        (apiResponse?.status_code > 400 && apiResponse?.status_code <= 418) ||
        apiResponse?.status_code >= 420
      ) {
        setFormError(
          apiResponse?.message
            ? Array.isArray(apiResponse?.message)
              ? apiResponse?.message[0]
              : apiResponse.message
            : apiResponse?.msg
            ? apiResponse?.msg
            : ""
        );
      }
    }
  };
  return (
    <>
      <InputFieldWithTitle
        titleClassName={`${s["input-title1"]}`}
        title={"PAN"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        value={userDetail?.pan_number}
      />
      <InputFieldWithTitle
        titleClassName={`${s["input-title1"]}`}
        title={"GST"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        value={userDetail?.gstin_number}
        errorIcon={errorInfoIcon}
      />
      <InputFieldWithTitle
        className={isMobile ? undefined : s["imp-mb24"]}
        titleClassName={`${s["input-title1"]}`}
        title={"TAN"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        value={userDetail?.tan}
      />
      <InputFieldWithTitle
        titleClassName={`${s["input-title1"]}`}
        title={"Relationship Manager Name"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        value={userDetail?.rmName}
      />
      <InputFieldWithTitle
        titleClassName={`${s["input-title1"]}`}
        title={"Relationship Manager Phone"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        value={userDetail?.rmPhone}
        errorIcon={errorInfoIcon}
      />
      <InputFieldWithTitle
        className={isMobile ? undefined : s["imp-mb24"]}
        titleClassName={`${s["input-title1"]}`}
        title={"Relationship Manager Email"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        value={userDetail?.rmEmail}
      />
      <div className={s["hr-line"]}></div>
      <UserInfoForm usersData={usersData} />

      {/* Add GST Button */}

      <div
        className={`${
          isMobile ? "margin-top24" : s["mt-32"]
        } d-flex justify-content-between align-items-center padding-bottom12 ${
          s["gst-detail-style"]
        }`}
      >
        <HeadLines
          className={` font-weight500 mt-1 ${
            isMobile
              ? `${s["add-gst-header-text-style"]} font-size16 line-height19`
              : `colorDark1 font-size20 line-height23`
          }`}
          text="GST Detail"
        />
        <Button
          className={
            isMobile
              ? `boxRadius6 backAccent3 font-size14 font-weight500 text-white text-center line-height16 ${s["add-gst-btn-style"]}`
              : `boxRadius6 backAccent3 font-size16 font-weight500 text-white text-center line-height19 ${s["add-gst-btn-style"]}`
          }
          title={"Add GST"}
          onClick={() => history.push("/manage-profile/add-gst-details")}
        />
      </div>
      {/* Show Gst Tab */}
      {gstDetails && gstDetails?.length > 0 && (
        <div
          className={` d-flex gap4 ${s["pill-style"]} ${
            isMobile ? "margin-top16" : s["mt-48"]
          }`}
        >
          {gstDetails.map((gstDetail, index) => {
            return (
              <div
                className={`${s["gst-pill"]} text-center  cursor-pointer ${
                  gstpillSelected?.documents?.[0]["docValue"] ===
                  gstDetail?.documents?.[0]["docValue"]
                    ? "backAccent4 text-white"
                    : `backLightGrey ${s["gst-pill-unselect"]}`
                }`}
                onClick={() => {
                  setGstPillSelected(gstDetail);
                  setIsDisableSave(true);
                  setFormError("");
                }}
              >
                <HeadLines
                  className={`font-weight500 ${
                    isMobile
                      ? " font-size14 line-height16"
                      : "font-size16 line-height19"
                  } `}
                  text={`${translation("GST")} ${index + 1}`}
                />
                <HeadLines
                  className={`font-weight400  font-size10 line-height12`}
                  text={gstDetail.gstStateName}
                />
              </div>
            );
          })}
        </div>
      )}
      {childEntities && childEntities?.length > 0 && (
        <>
          <div className={!isMobile && "margin-top30"}>
            <UpdateBillingForm
              pincode={
                gstpillSelected?.contactDetails?.[0]["address"]["pincode"]
              }
              state={
                gstpillSelected?.contactDetails?.[0]["address"]["state_name"]
              }
              city={
                gstpillSelected?.contactDetails?.[0]["address"]["district_name"]
              }
              permanantAddressLine1={
                gstpillSelected?.contactDetails?.[0]["address"]["addressLine1"]
              }
              gstState={gstpillSelected?.basicInfo?.gstState || ""}
              gstNumber={gstpillSelected?.basicInfo?.gst}
              panNumber={gstpillSelected?.basicInfo?.pan}
              tradeName={gstpillSelected?.basicInfo?.entityName}
              billingName={gstpillSelected?.contactDetails?.[0]["billingName"]}
              isNewGst={false}
              gstContactPersonDetails={
                gstpillSelected?.contactDetails?.[0]["contactPersons"]
              }
              setUpdateContactPerson={setUpdateContactPerson}
            />
          </div>

          {/* <HeadLines
        className={`${s["input-title1"]}`}
        text={"Do you want to proceed as a"}
      />
      <div className="d-flex flex-column gap6 margin-bottom16">
        <ProcessAsWidget
          className
          checkboxClassName
          text="Buyer"
          id="test1"
          checked={buyerChecked}
          onChange={buyerOnChange}
          disabled
        />
        <ProcessAsWidget
          className
          checkboxClassName
          text="Seller"
          id="test2"
          checked={sellerChecked}
          onChange={sellerOnChange}
          disabled
        />
      </div>
      <HeadLines
        className={`${s["input-title1"]}`}
        text={"Select your Trade Preferences"}
      />
      <Card className={`${s["card-style"]} `}>
        <HeadLines className={`${s["title2"]}`} text={"Locations"} />
        <CustomReactSelect
          // styles={customStyles}
          className={"HelloWorld"}
          options={options}
          isMulti={true}
        />
        <HeadLines
          className={`margin-top12 ${s["title2"]}`}
          text={"Commodity"}
        />
        <CustomReactSelect
          // styles={customStyles}
          className={"HelloWorld"}
          options={options}
          isMulti={true}
        />
      </Card> */}
          {formError && (
            <div className={"margin-bottom24"}>
              <ErrorMessage text={formError} />
            </div>
          )}
          <div
            className={
              isMobile ? `` : `w-50 mx-auto margin-bottom40 margin-top40 `
            }
          >
            <Button
              className={`cta-btn`}
              disabled={isDisableSave}
              title="Save"
              onClick={handleGSTContactUpdate}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AccountSettingForm;
