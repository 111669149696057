// v2/email-otp

//

import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";

const callApi = async (
  api,
  Method,
  data,
  wantRaw = false,
  wantRawApiResponse = false
) => {
  try {
    const response = await RestMethod[Method](api, data);
    if (wantRawApiResponse) return response?.data;
    const api_response = wantRaw ? response : FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (wantRaw) {
      return error.data;
    }
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const verifyEmailOTP = async (payload, wantRaw = false) => {
  return callApi(`v2/email-otp`, "POST", payload, wantRaw);
};

export const getUserDetails = async (urlParams, wantRaw = false) => {
  return callApi(`v2/user/details`, "GET");
};

export const postUserDetails = async (data, wantRaw = false) => {
  return callApi(`v2/update-marketplace-user?v3=1`, "POST", data, false, true);
};
export const postUserGstDetails = async (data, wantRaw = false) => {
  return callApi(`v2/add-gst?v3=1`, "POST", data, false, true);
};
