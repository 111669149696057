import React, { useEffect, useState } from "react";
import HeadLines from "../../../CommonComponents/HeadLines";
import s from "./style.module.css";
import { useTranslation } from "react-i18next";
const subArray = [
  { name: "Distance", onClick: null },
  { name: "Capacity (High to Low)", onClick: null },
  { name: "Capacity (Low to High)", onClick: null },
];
const OptionDropdown2 = React.forwardRef((props, ref) => {
  const {
    headingTittle = "Sort By",
    // sortOptions,
    // selectedOption,
    // setSelectedOption,
    // showDeskFilter,
    // setShowDeskFilter,
    className = "",
    show,
    sorting_by,
    setSorting_by,
    setShow,
  } = props;

  const [internalSelectedOption, setInternalSelectedOption] = useState(null);
  useEffect(() => {
    if (show) {
      setInternalSelectedOption(sorting_by);
    }
  }, [show]);
  const { t: translation } = useTranslation();
  return (
    <div className={`${s["w-card-style"]} ${className}`} ref={ref}>
      <HeadLines className={`${s["card-title"]} `} text={headingTittle} />
      <div className="">
        {subArray &&
          subArray.length > 0 &&
          subArray.map((item) => {
            return (
              <div
                className={`font-weight400 font-size16 ling-height19 letterSpacing05 colorDark7 cursor-pointer ${
                  s["card-options"]
                } ${sorting_by === item.name && s["activeOption"]}`}
                onClick={() => {
                  setSorting_by(item.name);
                  setShow();
                }}
              >
                {`${translation(item.name)}`}
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default OptionDropdown2;
